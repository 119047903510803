import { css, cva, cx } from '../styled-system/css';

import { Box } from './box';
import { SystemStyleObject } from '../styled-system/types';
import { forwardRef } from 'react';

export const button = cva({
  base: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2',

    color: 'button.text.color',
    fontWeight: 'semibold',

    cursor: 'pointer',

    _disabled: {
      pointerEvents: 'none',
    },
  },
  defaultVariants: { size: 'md', variant: 'text' },
  variants: {
    icon: {
      isIcon: {
        paddingY: 'button.icon.paddingY',
        paddingLeft: 'button.icon.paddingLeft',
        paddingRight: 'button.icon.paddingRight',
      },
      isText: { paddingY: 'button.text.paddingY', paddingX: 'button.text.paddingX' },
      isIconText: {
        paddingY: 'button.iconText.paddingY',
        paddingLeft: 'button.iconText.paddingLeft',
        paddingRight: 'button.iconText.paddingRight',
      },
    },

    size: {
      sm: {
        borderRadius: 'button.sm',
        fontSize: 'button.sm',
        paddingY: 'button.sm.paddingY',
        paddingX: 'button.sm.paddingX',
      },
      md: {
        borderRadius: 'button.md',
        fontSize: 'button.md',
        paddingY: 'button.md.paddingY',
        paddingX: 'button.md.paddingX',
      },
      lg: {
        borderRadius: 'button.lg',
        fontSize: 'button.lg',
        paddingY: 'button.lg.paddingY',
        paddingX: 'button.lg.paddingX',
      },
    },

    variant: {
      filled: {
        border: `1px solid token(colors.button.outlined.borderColor)`,
        background: 'button.filled.background',
        color: 'button.filled.color',
        _hover: { boxShadow: 'button.filled.hover.boxShadow' },
        _pressed: { opacity: 0.88 },
        _focus: { opacity: 0.88 },
        _disabled: {
          background: 'button.filled.disabled.background !important',
          boxShadow: 'none !important',
          color: 'button.filled.disabled.color',
        },
      },
      outlined: {
        border: `1px solid token(colors.button.outlined.borderColor)`,
        _hover: { background: 'button.outlined.hover.background' },
        _pressed: { background: 'button.outlined.hover.background' },
        _focus: { background: 'button.outlined.hover.background' },
        _disabled: { borderColor: 'button.outlined.disabled.borderColor', color: 'button.outlined.disabled.color' },
      },
      text: {
        _hover: { background: 'button.text.hover.background' },
        _pressed: { background: 'button.text.hover.background' },
        _focus: { background: 'button.text.hover.background' },
        _disabled: { color: 'button.text.disabled.color', opacity: 0.5 },
      },
      elevated: {
        boxShadow: 'button.elevated.boxShadow',
        _dark: { background: 'm3.surfaceContainerLow' },

        _hover: {
          background: 'button.elevated.hover.background',
          boxShadow: 'button.elevated.hover.boxShadow',
          _dark: { background: 'button.elevated.hover.dark.background' },
        },
        _pressed: {
          background: 'button.elevated.pressed.background',
          _dark: { background: 'button.elevated.pressed.dark.background' },
        },
        _focus: {
          background: 'button.elevated.focus.background',
          _dark: { background: 'button.elevated.focus.dark.background' },
        },
        _disabled: {
          background: 'button.elevated.disabled.background !important',
          boxShadow: 'none !important',
          color: 'button.elevated.disabled.color',
        },
      },
      tonal: {
        background: 'button.tonal.background',
        color: 'button.tonal.color',

        _hover: { boxShadow: 'button.tonal.hover.boxShadow' },
        _pressed: {},
        _focus: {},
        _disabled: {
          background: 'button.tonal.disabled.background !important',
          boxShadow: 'none !important',
          color: 'button.tonal.disabled.color',
        },
      },
      skeleton: {
        pointerEvents: 'none',
      },
    },
  },
});

export type Size = NonNullable<Parameters<typeof button>[0]>['size'];
type Variant = NonNullable<Parameters<typeof button>[0]>['variant'];
type HtmlButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export interface ButtonProps extends HtmlButtonProps {
  children?: React.ReactNode;
  css?: SystemStyleObject;
  icon?: React.ReactNode;
  iconAfter?: React.ReactNode;
  ref?: React.Ref<HTMLButtonElement>;
  size?: Size;
  variant?: Variant;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children = null, className, css: cssObject = {}, icon = null, iconAfter = null, size, variant, ...props },
    ref
  ) => {
    const iconVariant = getIconVariant({ icon, variant });

    return (
      <button
        className={cx(className, button({ icon: iconVariant, size, variant }), css(cssObject))}
        ref={ref}
        {...props}
      >
        {icon}
        {children || <>&nbsp;</>}
        {iconAfter}
      </button>
    );
  }
);

Button.displayName = 'Button';

export function getIconVariant({ icon, variant }: Pick<ButtonProps, 'icon' | 'variant'>) {
  switch (true) {
    case icon && variant === 'text':
      return 'isIconText';

    case variant === 'text':
      return 'isText';

    case !!icon:
      return 'isIcon';

    default:
      return;
  }
}
