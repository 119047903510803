import * as RadixForm from '@radix-ui/react-form';

import { css, cva, cx } from '../styled-system/css';
import { useCallback, useEffect, useRef } from 'react';

import { Box } from '../components/box';
import { SystemStyleObject } from '../styled-system/types';
import { forwardRef } from 'react';
import { typography } from '../components/typography';
import { useState } from 'react';

const field = cva({
  base: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    paddingX: '4',
    paddingY: '2',

    _focusWithin: {
      borderBottomWidth: '2px',
      marginBottom: '-1px',
      borderColor: 'form.borderColor',

      '& label': { color: 'form.borderColor' },
    },

    _invalid: {
      borderColor: 'm3.error !important',

      '& .form-message': { color: 'm3.error' },
    },

    '&:has(:disabled)': {
      opacity: '0.5',
      pointerEvents: 'none',
    },
  },
  defaultVariants: { variant: 'filled' },
  variants: {
    variant: {
      filled: {
        background: 'form.background',
        borderBottom: '1px solid token(colors.form.borderColor)',
        borderTopLeftRadius: 'sm',
        borderTopRightRadius: 'sm',
      },
      outlined: {
        border: '1px solid token(colors.form.borderColor)',
        borderRadius: 'sm',
      },
    },
  },
});

type Css = { css?: SystemStyleObject };
export type FieldProps = { outlined?: boolean } & RadixForm.FormFieldProps & Css;

function Field({ children, className, css: cssObject = {}, outlined = false, ...props }: FieldProps) {
  return (
    <RadixForm.Field asChild {...props}>
      <RadixForm.ValidityState>
        {(validity) => {
          let validityProps: Record<string, boolean> = {};

          if (validity?.valid === true) {
            validityProps['data-valid'] = true;
          } else if (validity?.valid === false) {
            validityProps['data-invalid'] = true;
          }

          return (
            <Box className={cx('form-field', className)} css={{ paddingBottom: '6' }}>
              <Box
                className={cx(field({ variant: outlined ? 'outlined' : 'filled' }), css(cssObject))}
                {...validityProps}
              >
                <>{children}</>
              </Box>
            </Box>
          );
        }}
      </RadixForm.ValidityState>
    </RadixForm.Field>
  );
}

type LabelProps = { backgroundColor?: string; outlined?: FieldProps['outlined'] } & RadixForm.LabelProps & Css;

const label = cva({
  base: {
    _invalid: { color: 'm3.error !important' },
  },
  defaultVariants: { variant: 'filled' },
  variants: {
    variant: {
      filled: {
        fontSize: 'xs',
      },
      outlined: {
        position: 'absolute',
        top: 'calc(50% - 0.75rem)',

        '&:has(+ .input[data-has-value=true], + .input[data-invalid=true], + .input:focus, + .input:placeholder-shown)':
          {
            top: '-10px',
            paddingX: '1',
            marginLeft: '-1',
            fontSize: 'xs',
          },
      },
    },
  },
});

function Label({ backgroundColor, css: cssObject = {}, outlined, ...props }: LabelProps) {
  return (
    <RadixForm.Label
      className={cx(
        label({ variant: outlined ? 'outlined' : 'filled' }),
        css({ backgroundColor, color: 'm3.onSurface', ...cssObject })
      )}
      {...props}
    />
  );
}

function Message({ css: cssObject = {}, ...props }: RadixForm.FormMessageProps & Css) {
  return (
    <RadixForm.Message
      className={cx(
        typography({ type: 'caption' }),
        css({ position: 'absolute', left: '4', bottom: '-5', marginBottom: '-1', ...cssObject }),
        'form-message'
      )}
      {...props}
    />
  );
}

const Input = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement> & Css>(function Input(
  { css: cssObject = {}, ...props },
  ref
) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [hasValue, setHasValue] = useState(false);
  const onChange = useCallback(() => {
    setHasValue(inputRef?.current?.value !== '');
  }, []);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.addEventListener('change', onChange);

      onChange();

      return () => inputRef.current?.removeEventListener('change', onChange);
    }
  }, [inputRef?.current?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <input
      className={cx(
        'input',
        typography({ type: 'body2' }),
        css({
          background: 'none',
          paddingX: '2',
          paddingY: '1',
          position: 'relative',
          left: '-2',
          _focus: {
            outline: 'none',
          },
          ...cssObject,
        })
      )}
      data-has-value={hasValue}
      ref={(node) => {
        inputRef.current = node;

        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
      {...props}
    />
  );
});

const Textarea = forwardRef<HTMLTextAreaElement, React.TextareaHTMLAttributes<HTMLTextAreaElement> & Css>(
  function Textarea({ css: cssObject = {}, ...props }, ref) {
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const [hasValue, setHasValue] = useState(false);
    const onChange = useCallback(() => setHasValue(inputRef?.current?.value !== ''), []);

    useEffect(() => {
      if (inputRef?.current) {
        inputRef.current.addEventListener('change', onChange);

        return () => inputRef.current?.removeEventListener('change', onChange);
      }
    }, [inputRef?.current?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <textarea
        className={cx(
          'input',
          css({
            background: 'none',
            borderRadius: 'xs',
            _focus: {
              outline: 'none',
            },
            ...cssObject,
          })
        )}
        data-has-value={hasValue}
        ref={(node) => {
          inputRef.current = node;

          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        {...props}
      />
    );
  }
);

export const Form = {
  Root: RadixForm.Root,
  Field,
  Input,
  Textarea,
  Label,
  Control: RadixForm.Control,
  Message,
  ValidityState: RadixForm.ValidityState,
  Submit: RadixForm.Submit,
};

export type FormType = typeof Form;
