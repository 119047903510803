import { Button, ButtonProps } from './button';
import { FieldProps, Form as RadixForm, FormType as RadixFormType } from '../radix-ui/form';
import { forwardRef, useRef } from 'react';

import { Box } from './box';
import { FormMessageProps } from '@radix-ui/react-form';
import { SystemStyleObject } from '../styled-system/types';
import { button } from './sprite-icon-button';
import { css } from '../styled-system/css';

export type FormType = RadixFormType & {
  ControlledInput: typeof ControlledInput;
  ControlledTextarea: typeof ControlledTextarea;
  SubmitButton: typeof SubmitButton;
};

type RadixInputProps = Parameters<typeof RadixForm.Input>[0];
type Messages = [FormMessageProps['match'], string][];

const CANCEL_BUTTON_CLASSNAME = button({ size: '2xl', toggle: 'cannotToggle', variant: 'standard' });

export type ControlledInputProps = Omit<RadixInputProps, 'beforeIcon'> & {
  labelBackgroundColor?: string;
  beforeIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  errorIcon?: React.ReactNode;
  label?: string;
  messages?: Messages;
  name: string;
  outlined?: FieldProps['outlined'];
};

const ControlledInput = forwardRef<HTMLInputElement, ControlledInputProps>(
  (
    {
      labelBackgroundColor = 'var(--colors-m3-surface-container-lowest)',
      beforeIcon = null,
      cancelIcon = null,
      errorIcon = null,
      label,
      messages = [],
      name,
      outlined,
      ...props
    },
    ref
  ) => {
    const backupRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || backupRef;
    const mergedMessages = mergeDefaultMessages(messages);

    function onCancelClick() {
      const input = (inputRef as React.RefObject<HTMLInputElement>)?.current;

      if (input) {
        input.focus();
        input.value = '';
      }
    }

    return (
      <Form.Field name={name} outlined={outlined}>
        <RadixForm.ValidityState>
          {(validity) => {
            return (
              <>
                <Box css={{ display: 'flex', alignItems: 'center', gap: '2' }}>
                  <Box css={{ marginLeft: '-2' }}>{beforeIcon}</Box>

                  <Box css={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <Form.Label backgroundColor={outlined ? labelBackgroundColor : ''} outlined={outlined}>
                      {label}
                    </Form.Label>
                    <Form.Control asChild>
                      <Form.Input ref={inputRef} {...props} />
                    </Form.Control>
                  </Box>

                  <Box css={{ marginRight: '-2' }}>
                    {cancelIcon ? (
                      validity?.valid === false ? (
                        <div className={CANCEL_BUTTON_CLASSNAME} style={{ pointerEvents: 'none' }}>
                          {errorIcon}
                        </div>
                      ) : (
                        <button className={CANCEL_BUTTON_CLASSNAME} onClick={onCancelClick}>
                          {cancelIcon}
                        </button>
                      )
                    ) : null}
                  </Box>
                </Box>
                {mergedMessages.map(([match, text]) => (
                  <Form.Message data-match={match} key={match?.toString()} match={match}>
                    {text}
                  </Form.Message>
                ))}
              </>
            );
          }}
        </RadixForm.ValidityState>
      </Form.Field>
    );
  }
);

ControlledInput.displayName = 'ControlledInput';

function mergeDefaultMessages(messages: Messages): Messages {
  const result: Messages = [
    ['valueMissing', 'This field is required'],
    ['typeMismatch', 'This field is invalid'],
    ['patternMismatch', 'This field is invalid'],
    ['tooShort', 'This field is too short'],
    ['tooLong', 'This field is too long'],
    ['rangeUnderflow', 'This field is too small'],
    ['rangeOverflow', 'This field is too large'],
    ['stepMismatch', 'This field is invalid'],
    ['badInput', 'This field is invalid'],
  ];

  for (const [match, text] of messages) {
    const index = result.findIndex(([m]) => m?.toString() === match?.toString());

    if (index === -1) {
      result.push([match, text]);
    } else {
      result[index] = [match, text];
    }
  }

  return result;
}

type RadixTextareaProps = Parameters<typeof RadixForm.Textarea>[0];
export type ControlledTextareaProps = {
  backgroundColor?: string;
  css?: SystemStyleObject;
  label?: string;
  messages?: Messages;
  name: string;
  outlined?: FieldProps['outlined'];
} & RadixTextareaProps;

function ControlledTextarea({
  backgroundColor,
  css: cssObject = {},
  label,
  messages = [],
  name,
  outlined,
  ...props
}: ControlledTextareaProps) {
  return (
    <Form.Field name={name} outlined={outlined}>
      <RadixForm.ValidityState>
        {(validity) => (
          <>
            <Box className={css(cssObject)} css={{ display: 'flex', alignItems: 'center', gap: '2' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                <Form.Label backgroundColor={backgroundColor} outlined={outlined}>
                  {label}
                </Form.Label>
                <Form.Control asChild>
                  <Form.Textarea {...props} />
                </Form.Control>
              </Box>
            </Box>
            {messages.map(([match, text]) => (
              <Form.Message key={match?.toString()} match={match}>
                {text}
              </Form.Message>
            ))}
          </>
        )}
      </RadixForm.ValidityState>
    </Form.Field>
  );
}

function SubmitButton(props: ButtonProps) {
  return (
    <Form.Submit asChild>
      <Button {...props} />
    </Form.Submit>
  );
}

export function setInputValue(id: string, value: string) {
  const input = document.getElementById(id) as HTMLInputElement;

  if (input) {
    input.value = value;

    input.dispatchEvent(new Event('change', { bubbles: true }));
  }
}

export const Form: FormType = {
  Root: RadixForm.Root,
  Field: RadixForm.Field,
  Input: RadixForm.Input,
  Textarea: RadixForm.Textarea,
  Label: RadixForm.Label,
  Control: RadixForm.Control,
  Message: RadixForm.Message,
  ValidityState: RadixForm.ValidityState,
  Submit: RadixForm.Submit,
  ControlledInput,
  ControlledTextarea,
  SubmitButton,
};
