import { SVGProps } from 'react';
import spritesManifest from './sprites-manifest.json';

type ValueOf<T> = T[keyof T];
export type IconName = ValueOf<typeof spritesManifest>;

const SPRITES_SIZE_PX = {
  '2xs': '0.5rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
} as const;
export type SpriteIconProps = {
  family?: 'filled' | 'outlined';
  name: IconName;
  path: string;
  size?: keyof typeof SPRITES_SIZE_PX;
} & SVGProps<SVGSVGElement>;

export function SpriteIcon({ family = 'filled', name, path, size = 'md', ...props }: SpriteIconProps) {
  return (
    <svg height={SPRITES_SIZE_PX[size]} width={SPRITES_SIZE_PX[size]} {...props}>
      <use height={SPRITES_SIZE_PX[size]} href={`${path}#${name}_${family}`} width={SPRITES_SIZE_PX[size]} />
    </svg>
  );
}
