import { SpriteIcon, SpriteIconProps } from '../material/components/sprites';
import { css, cva, cx } from '../styled-system/css';

import { HtmlDivProps } from './box';
import { SystemStyleObject } from '../styled-system/types';
import { forwardRef } from 'react';

export const button = cva({
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: '2',
    padding: '1',

    color: 'm3.onSurfaceVariant',
    borderRadius: 'full',
    cursor: 'pointer',
  },
  defaultVariants: { size: 'md', toggle: 'cannotToggle', variant: 'standard' },
  variants: {
    size: {
      '2xs': {},
      xs: {},
      sm: {},
      md: {},
      lg: {},
      xl: { padding: '2' },
      '2xl': { padding: '2' },
      '3xl': { padding: '2' },
      '4xl': { padding: '2' },
      '5xl': { padding: '2' },
      '6xl': { padding: '2' },
      '7xl': { padding: '2' },
      '8xl': { padding: '2' },
      '9xl': { padding: '2' },
    },
    toggle: {
      cannotToggle: {},
      isToggled: {
        color: 'm3.primary',
      },
      isNotToggled: {},
    },
    variant: {
      standard: {
        _hover: { background: 'm3.onSurfaceVariant008' },
        _pressed: { background: 'm3.onSurfaceVariant008' },
        _focus: { background: 'm3.onSurfaceVariant008' },
        _disabled: { color: 'm3.onSurface038', cursor: 'default' },
      },

      filled: {
        background: 'm3.primary',
        color: 'm3.onPrimary',

        '&[data-value="false"]': {
          background: 'm3.surfaceContainerHighest',
          color: 'm3.primary',
        },

        _pressed: { opacity: 0.88 },
        _focus: { opacity: 0.88 },
        _disabled: {
          background: 'm3.onSurface012 !important',
          boxShadow: 'none !important',
          color: 'm3.onSurface038',
          cursor: 'default',
        },
      },
      tonal: {
        background: 'm3.secondaryContainer',
        color: 'm3.onSecondaryContainer',

        '&[data-value="false"]': {
          background: 'm3.surfaceContainerHighest',
        },

        _disabled: {
          background: 'm3.onSurface012 !important',
          boxShadow: 'none !important',
          color: 'm3.onSurface038',
          cursor: 'default',
        },
      },
      outlined: {
        border: '1px solid token(colors.m3.outline)',

        '&[data-value="unset"], &[data-value="false"]': {
          _hover: { background: 'm3.surfaceTint008' },
          _pressed: { background: 'm3.surfaceTint008' },
          _focus: { background: 'm3.surfaceTint008' },
          _disabled: { borderColor: 'm3.onSurface012', color: 'm3.onSurface038', cursor: 'default' },
        },

        '&[data-value="true"]': {
          background: 'm3.inverseSurface',
          color: 'm3.inverseOnSurface',

          _disabled: {
            background: 'm3.onSurface012',
            borderColor: 'transparent',
            boxShadow: 'none !important',
            color: 'm3.onSurface038',
            cursor: 'default',
          },
        },
      },
    },
  },
});

type HtmlButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type Variant = NonNullable<Parameters<typeof button>[0]>['variant'];

type SpriteIconSharedProps = {
  css?: SystemStyleObject;
  family?: SpriteIconProps['family'];
  icon?: React.ReactNode;
  name?: SpriteIconProps['name'];
  path?: SpriteIconProps['path'];
  size?: SpriteIconProps['size'];
  value?: boolean;
  variant?: Variant;
  'data-hover'?: boolean;
  'data-focus'?: boolean;
  'data-pressed'?: boolean;
};

export type SpriteIconButtonProps = SpriteIconSharedProps & Omit<HtmlButtonProps, 'value'>;
export const SpriteIconButton = forwardRef<HTMLButtonElement, SpriteIconButtonProps>(function SpriteIconButton(
  { children = null, className, css: cssObject = {}, family, icon = null, name, path, size, value, variant, ...props },
  ref
) {
  const toggle = getToggle(value);
  const iconElement = getIconElement({ family, icon, name, path, size, value });

  return (
    <button
      className={cx(className, button({ size, toggle, variant }), css(cssObject))}
      data-size={size}
      data-value={value ?? 'unset'}
      ref={ref}
      {...props}
    >
      {iconElement}
    </button>
  );
});

export type SpriteIconDivProps = SpriteIconSharedProps & HtmlDivProps;
export const SpriteIconDiv = forwardRef<HTMLDivElement, SpriteIconDivProps>(function SpriteIconDiv(
  { children = null, className, css: cssObject = {}, family, icon = null, name, path, size, value, variant, ...props },
  ref
) {
  const toggle = getToggle(value);
  const iconElement = getIconElement({ family, icon, name, path, size, value });

  return (
    <div
      className={cx(className, button({ size, toggle, variant }), css(cssObject))}
      data-size={size}
      data-value={value ?? 'unset'}
      ref={ref}
      {...props}
    >
      {iconElement}
    </div>
  );
});

function getIconElement({
  family: familyOverride,
  icon,
  name,
  path,
  size,
  value,
}: Pick<SpriteIconButtonProps, 'family' | 'icon' | 'name' | 'path' | 'size' | 'value'>) {
  const family = familyOverride || getIconFamily({ value });

  switch (true) {
    case !!icon:
      return icon;
    case !!name && !!path:
      return <SpriteIcon family={family} name={name!} path={path!} size={size} />;
    default:
      return null;
  }
}

function getIconFamily({ value }: Pick<SpriteIconButtonProps, 'value'>): SpriteIconProps['family'] {
  switch (value) {
    case true:
      return 'filled';

    case false:
      return 'outlined';

    default:
      return 'outlined';
  }
}

function getToggle(value?: Boolean) {
  switch (value) {
    case true:
      return 'isToggled';
    case false:
      return 'isNotToggled';
    default:
      return 'cannotToggle';
  }
}
