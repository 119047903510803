import { css, cva, cx } from '../styled-system/css';

import { SystemStyleObject } from '../styled-system/types';

/**
 * --font-sizes-2xs: 0.5rem;
    --font-sizes-xs: 0.75rem;
    --font-sizes-sm: 0.875rem;
    --font-sizes-md: 1rem;
    --font-sizes-lg: 1.125rem;
    --font-sizes-xl: 1.25rem;
    --font-sizes-2xl: 1.5rem;
    --font-sizes-3xl: 1.875rem;
    --font-sizes-4xl: 2.25rem;
    --font-sizes-5xl: 3rem;
    --font-sizes-6xl: 3.75rem;
    --font-sizes-7xl: 4.5rem;
    --font-sizes-8xl: 6rem;
    --font-sizes-9xl: 8rem;
 */

export const typography = cva({
  base: {
    color: 'type.color',
    fontWeight: 'bold',
    '&::selection': { background: 'm3.tertiary', color: 'm3.onTertiary' },
  },
  defaultVariants: { as: 'span', type: 'body1' },
  variants: {
    as: {
      h1: {},
      h2: {},
      h3: {},
      h4: {},
      h5: {},
      h6: {},
      div: {},
      span: {},
      p: {},
    },
    type: {
      h1: {
        lineHeight: { base: 'type.h1.base', sm: 'type.h1.sm', md: 'type.h1.md' },
        fontSize: { base: 'type.h1.base', sm: 'type.h1.sm', md: 'type.h1.md' },
      },
      h2: {
        fontSize: { base: 'type.h2.base', sm: 'type.h2.sm', md: 'type.h2.md' },
        lineHeight: { base: 'type.h2.base', sm: 'type.h2.sm', md: 'type.h2.md' },
      },
      h3: {
        fontSize: { base: 'type.h3.base', sm: 'type.h3.sm', md: 'type.h3.md' },
        lineHeight: { base: 'type.h3.base', sm: 'type.h3.sm', md: 'type.h3.md' },
      },
      h4: {
        fontSize: { base: 'type.h4.base', sm: 'type.h4.sm', md: 'type.h4.md' },
        lineHeight: { base: 'type.h4.base', sm: 'type.h4.sm', md: 'type.h4.md' },
      },
      h5: {
        fontSize: { base: 'type.h5.base', sm: 'type.h5.sm', md: 'type.h5.md' },
        lineHeight: { base: 'type.h5.base', sm: 'type.h5.sm', md: 'type.h5.md' },
      },
      h6: {
        fontSize: { base: 'type.h6.base', sm: 'type.h6.sm', md: 'type.h6.md' },
        lineHeight: { base: 'type.h6.base', sm: 'type.h6.sm', md: 'type.h6.md' },
      },

      subtitle1: {
        fontWeight: 600,
        fontSize: { base: 'type.subtitle1.base', sm: 'type.subtitle1.sm', md: 'type.subtitle1.md' },
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: { base: 'type.subtitle2.base', sm: 'type.subtitle2.sm', md: 'type.subtitle2.md' },
      },
      body1: {
        fontWeight: 400,
        fontSize: { base: 'type.body1.base', sm: 'type.body1.sm', md: 'type.body1.md' },
      },
      body2: {
        fontWeight: 400,
        fontSize: { base: 'type.body2.base', sm: 'type.body2.sm', md: 'type.body2.md' },
      },
      caption: {
        fontWeight: 400,
        fontSize: { base: 'type.caption.base', sm: 'type.caption.sm', md: 'type.caption.md' },
      },
    },
  },
});

export type TypographyVariants = Parameters<typeof typography>[0];
type TypographyElements = HTMLParagraphElement | HTMLDivElement | HTMLHeadingElement;
type HtmlProps = React.DetailedHTMLProps<React.HTMLAttributes<TypographyElements>, TypographyElements>;

export type TypographyProps = {
  children?: React.ReactNode;
  css?: SystemStyleObject;
} & HtmlProps &
  TypographyVariants;

export function Typography({ children = null, className, css: cssObject = {}, ...props }: TypographyProps) {
  const { type } = props;
  const variants = { type };

  return (
    <HtmlTag className={cx(className, typography(variants), css(cssObject))} {...props}>
      {children}
    </HtmlTag>
  );
}

function HtmlTag({ children, as: Tag = 'span', type, ...props }: TypographyProps) {
  switch (Tag) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
    case 'div':
    case 'p':
    case 'span':
      return <Tag {...props}>{children}</Tag>;
    default:
  }

  switch (type) {
    case 'h1':
      return <h1 {...props}>{children}</h1>;
    case 'h2':
      return <h2 {...props}>{children}</h2>;
    case 'h3':
      return <h3 {...props}>{children}</h3>;
    case 'h4':
      return <h4 {...props}>{children}</h4>;
    case 'h5':
      return <h5 {...props}>{children}</h5>;
    case 'h6':
      return <h6 {...props}>{children}</h6>;
    case 'caption':
      return <aside {...props}>{children}</aside>;
    case 'subtitle1':
    case 'subtitle2':
    case 'body1':
    case 'body2':
    default:
      return <span {...props}>{children}</span>;
  }
}
